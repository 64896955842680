import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { AppModel } from '../models/config.model';

export const apps: AppModel[] = [
  {
    id: 'map',
    serverUrl: '/map/',
    openApiUrl: '/map/v1.0/openapi.json',
    nameResource: _('Dev.Api.Psm.Name'),
    descriptionResource: _('Dev.Api.Psm.Description'),
    howResource: _('Dev.Api.Psm.How'),
    whatResource: _('Dev.Api.Psm.What'),
    whoResource: _('Dev.Api.Psm.Who'),
  },
  {
    id: 'pak',
    serverUrl: '/parcel-shipment/',
    openApiUrl: '/parcel-shipment/swagger/v1/swagger.json',
    nameResource: _('Dev.Api.Pak.Name'),
    descriptionResource: _('Dev.Api.Pak.Description'),
    howResource: _('Dev.Api.Pak.How'),
    whatResource: _('Dev.Api.Pak.What'),
    whoResource: _('Dev.Api.Pak.Who'),
  },
];
